import { Typography, ButtonBase } from '@material-ui/core';
import { TextHtml } from '@/client/components';
import clsx from 'clsx';
import useStyles from './styles';
import { IReadMoreTextProps } from './types';
import useReadMoreText from './useReadMoreText';

export const ReadMoreText = ({
  text,
  textClass,
  paragraph,
  lengthLimit,
  hasReadMoreButton,
  ...props
}: IReadMoreTextProps) => {
  const classes = useStyles();
  const { trimmedText, showReadMoreButton, readMoreButtonText, readMoreHandler } = useReadMoreText({
    text,
    paragraph,
    lengthLimit,
    hasReadMoreButton,
  });

  return (
    <div {...props}>
      <Typography className={clsx(classes.description, textClass)} component="div" variant="body1">
        {paragraph && <span className={classes.paragraph}>{paragraph} </span>}
        <TextHtml text={trimmedText}>
          {showReadMoreButton && (
            <ButtonBase className={classes.readMoreButton} onClick={readMoreHandler} disableRipple>
              {readMoreButtonText}
            </ButtonBase>
          )}
        </TextHtml>
      </Typography>
    </div>
  );
};

export default ReadMoreText;
