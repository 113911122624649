import { ButtonBase, Typography } from '@material-ui/core';
import { useMemo } from 'react';
import { IViewLinksContainerProps } from './types';
import useViewLinksContainer from './useViewLinksContainer';
import useStyles from './styles';

export const ViewLinksContainer = ({
  links,
  titleClassName,
  ...props
}: IViewLinksContainerProps) => {
  const classes = useStyles();
  const { handleOpenLink } = useViewLinksContainer();

  const title = useMemo(
    () => (
      <Typography className={titleClassName ?? classes.title} variant="h3">
        LinkedIn URLs of perfect candidates
      </Typography>
    ),
    [classes.title, titleClassName],
  );

  if (!links.length) {
    return (
      <>
        {title}
        <Typography variant="body2" className={classes.text}>
          None
        </Typography>
      </>
    );
  }

  return (
    <div className={classes.linksBlock} {...props}>
      {title}
      {links.map((link, idx) => (
        <ButtonBase onClick={handleOpenLink(link)} key={link + idx}>
          <Typography variant="body2" className={classes.counter}>
            {idx + 1}.{' '}
          </Typography>
          <Typography variant="body2" className={classes.btnText}>
            {link}
          </Typography>
        </ButtonBase>
      ))}
    </div>
  );
};

export default ViewLinksContainer;
