import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(15),
      border: `1px solid ${theme.palette.border}`,
      borderRadius: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(12, 10),
    },
  },
  listItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:last-child > div': {
      border: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'calc(100%/3)',
      width: 'fit-content',
    },
  },
  icon: {
    minWidth: theme.spacing(10),
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginRight: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(12),
      width: theme.spacing(12),
      height: theme.spacing(12),
      marginRight: theme.spacing(7),
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(10),
    },
  },
  caption: {
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.secondaryBlack,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fonts.size.sm,
      lineHeight: theme.typography.fonts.lineHeight.sm,
    },
    [theme.breakpoints.up('lg')]: {
      lineHeight: '15px',
    },
  },
  label: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.fonts.size.sm,
    fontWeight: 600,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    color: theme.palette.blackMain,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.fonts.size.md,
      fontWeight: 700,
      lineHeight: theme.typography.fonts.lineHeight.md,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(3),
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '20px',
    },
  },
  textBlock: {
    width: '100%',
    fontSize: theme.typography.fonts.size.md,
    padding: theme.spacing(7, 0),
    borderBottom: `1px solid ${theme.palette.border}`,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0),
      border: 'none',
    },
  },
}));

export default useStyles;
