import { Button } from '@/client/components';
import { getLocationFromAddress } from '@/client/utils';
import { generateGeneralPayment, generatePayment } from '@/utils';
import { Hidden, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';
import useCardStyles from '../../../styles';
import useStyles from './styles';
import { IMainJobInfo } from './types';

export const CandidateJobDetailedCard = ({
  jobInfo,
  name: companyName,
  className,
  button,
  classes,
  ...props
}: IMainJobInfo) => {
  const cardClasses = useCardStyles();
  const internalClasses = useStyles();

  return (
    <div className={clsx(internalClasses.root, className)} {...props}>
      <div className={internalClasses.topWrapper}>
        <div className={internalClasses.infoContainer}>
          <div className={internalClasses.titleBlock}>
            <Typography
              className={clsx(internalClasses.title, classes?.jobTitleClass, {
                [internalClasses.isNotMatch]: !jobInfo.match,
              })}
              variant="h1"
            >
              {jobInfo.jobTitle}
            </Typography>
            {jobInfo.match && (
              <div className={clsx(cardClasses.badge, internalClasses.badge)}>
                <div className={cardClasses.badgeIcon}>
                  <Image src="/icons/star-icon.svg" width={12} height={12} />
                </div>
                <Typography className={cardClasses.badgeTitle} variant="body1">
                  Match
                </Typography>
              </div>
            )}
          </div>
          <div className={clsx(internalClasses.infoBlock, classes?.infoTextClass)}>
            <div className={clsx(internalClasses.companyNameBlock, classes?.companyNameClass)}>
              <Typography className={internalClasses.infoText}>{companyName}</Typography>
              <div className={internalClasses.point} />
            </div>
            {jobInfo.workTime && (
              <>
                <Typography className={internalClasses.infoText}>{jobInfo.workTime}</Typography>
                <div className={internalClasses.point} />
              </>
            )}
            <Typography className={internalClasses.infoText}>
              {`Candidate Location: ${
                jobInfo.isRemote
                  ? `Remote ${
                      jobInfo.preferredAddress
                        ? `(${getLocationFromAddress(jobInfo.preferredAddress)})`
                        : ''
                    }`
                  : 'Not Remote'
              } `}
            </Typography>
          </div>
        </div>
        {jobInfo.salaryFrom !== null && jobInfo.salaryTo !== null && (
          <Hidden xsDown implementation="css">
            <div>
              <Typography
                className={clsx(internalClasses.boldText, classes?.boldTextClass)}
                variant="body1"
              >
                $ {generateGeneralPayment(jobInfo.salaryFrom, jobInfo.salaryTo, 'year')} annually
              </Typography>

              <Typography
                className={clsx(internalClasses.boldText, internalClasses.smallerText)}
                variant="body1"
              >
                $ {generateGeneralPayment(jobInfo.salaryFrom, jobInfo.salaryTo, 'month')}{' '}
                <span className={internalClasses.secondaryText}>monthly</span>
              </Typography>

              <Typography
                className={clsx(internalClasses.boldText, internalClasses.smallerText)}
                variant="body1"
              >
                $ {generatePayment(jobInfo.salaryFrom ?? 0, jobInfo.salaryTo ?? undefined)}
                <span className={internalClasses.secondaryText}>/ hour</span>
              </Typography>
            </div>
          </Hidden>
        )}
      </div>

      {button && (
        <div className={internalClasses.btnWrapper}>
          <Button
            className={clsx(internalClasses.button, classes?.buttonClass)}
            onClick={button.onClick}
            disabled={button.disabled}
          >
            {button.label}
          </Button>
        </div>
      )}
      {jobInfo.salaryFrom !== null && jobInfo.salaryTo !== null && (
        <Hidden smUp implementation="css">
          <div className={internalClasses.paymentBlockForMobile}>
            <Typography className={internalClasses.boldText} variant="body1">
              ${generateGeneralPayment(jobInfo.salaryFrom, jobInfo.salaryTo, 'year')}
              <span className={internalClasses.secondaryText}> annually</span>
            </Typography>
          </div>
        </Hidden>
      )}
    </div>
  );
};

export default CandidateJobDetailedCard;
