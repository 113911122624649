import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import Image from 'next/image';
import useStyles from './styles';
import { IListBlockProps } from './types';

export const ListBlock = ({ list, className, listItemClass, ...props }: IListBlockProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapper, className)} {...props}>
      {list.map((item, index) => (
        <div className={clsx(classes.listItem, listItemClass)} key={index}>
          <div className={classes.icon}>
            <Image src={item.iconSrc} width={24} height={24} />
          </div>
          <div className={classes.textBlock}>
            <Typography className={classes.caption} variant="body1">
              {item.caption}
            </Typography>
            <Typography className={classes.label} variant="body1">
              {item.label}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListBlock;
