import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  topWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  infoContainer: {
    [theme.breakpoints.up('sm')]: {
      flex: '1',
    },
  },
  title: {
    marginTop: theme.spacing(9),
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '22px',
    color: theme.palette.blackMain,
    order: 2,
    [theme.breakpoints.up('sm')]: {
      order: 'initial',
      margin: 0,
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '30px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '28px',
      lineHeight: '35px',
    },
  },
  boldText: {
    width: '100%',
    margin: theme.spacing(2, 0),
    paddingRight: theme.spacing(2.5),
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
    color: theme.palette.blackMain,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '30px',
      textAlign: 'right',
    },
  },
  secondaryText: {
    fontSize: theme.typography.fonts.size.sm,
    fontWeight: 400,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    marginTop: theme.spacing(2),
    color: theme.palette.secondaryBlack,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  infoBlock: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
  },
  infoText: {
    fontSize: theme.typography.fonts.size.sm,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '25px',
    },
  },
  titleBlock: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  paymentBlockForMobile: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(7, -10, 0),
    padding: theme.spacing(4, 10),
    borderTop: `1px solid ${theme.palette.border}`,
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  btnWrapper: {
    position: 'fixed',
    zIndex: 9,
    backgroundColor: theme.palette.white,
    width: '100%',
    height: theme.spacing(35),
    bottom: theme.spacing(10),
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      background: 'transparent',
      height: theme.spacing(20),
      display: 'block',
      position: 'relative',
      maxWidth: theme.spacing(75),
      transform: 'none',
      bottom: 0,
      left: 0,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(6),
    },
  },
  button: {
    height: theme.spacing(20),
    width: 'Calc(100vw - 40px)',

    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(75),
    },
  },
  badge: {
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: { marginLeft: theme.spacing(4) },
    [theme.breakpoints.up('lg')]: { marginLeft: theme.spacing(5) },
  },
  devider: {
    height: theme.spacing(15),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  point: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    margin: theme.spacing(0, 3),
    backgroundColor: theme.palette.blackMain,
    borderRadius: '50%',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 6),
    },
  },
  isNotMatch: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(20),
    },
  },
  companyNameBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  smallerText: {
    fontSize: theme.typography.fonts.size.md,
    fontWeight: 400,
    lineHeight: theme.typography.fonts.lineHeight.md,
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '20px',
    },
  },
}));

export default useStyles;
