import { useCallback } from 'react';

const useViewLinksContainer = () => {
  const handleOpenLink = useCallback(
    (link: string) => () => {
      window.open(link);
    },
    [],
  );

  return {
    handleOpenLink,
  };
};

export default useViewLinksContainer;
