import { useCallback, useMemo, useState } from 'react';
import { IReadMoreTextProps } from './types';

const trimText = (paragraphLength: number, lengthLimit: number, text: string) => {
  const length = Math.min(lengthLimit - paragraphLength, text.length);

  return text.substring(0, length);
};

const useReadMoreText = ({
  text,
  paragraph = '',
  lengthLimit = 350,
  hasReadMoreButton = false,
}: IReadMoreTextProps) => {
  const [readMore, setReadMore] = useState(true);

  const { readMoreButtonText, trimmedText, showReadMoreButton } = useMemo(() => {
    if (!hasReadMoreButton || text.length <= lengthLimit) {
      return {
        showReadMoreButton: false,
        trimmedText: trimText(paragraph.length, lengthLimit, text),
      };
    }

    if (readMore) {
      return {
        showReadMoreButton: true,
        readMoreButtonText: 'Read More',
        trimmedText: trimText(paragraph.length, lengthLimit, text),
      };
    }

    return {
      showReadMoreButton: true,
      readMoreButtonText: 'Show Less',
      trimmedText: text,
    };
  }, [hasReadMoreButton, lengthLimit, paragraph.length, readMore, text]);

  const readMoreHandler = useCallback((e) => {
    e.stopPropagation();
    setReadMore((oldValue) => !oldValue);
  }, []);

  return {
    readMoreButtonText,
    trimmedText,
    showReadMoreButton,
    readMoreHandler,
  };
};

export default useReadMoreText;
