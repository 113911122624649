import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  linksBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  counter: {
    marginRight: theme.spacing(5),
  },
  btnText: {
    textAlign: 'start',
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '25px',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(11),
      marginBottom: theme.spacing(8),
      fontSize: '18px',
      lineHeight: '30px',
    },
  },
  text: {
    color: '#83879C',
    fontSize: theme.typography.fonts.size.sm,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.0016em',
    },
  },
}));

export default useStyles;
