import { makeStyles } from '@material-ui/core';

const useCardStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(10),
    fontSize: theme.typography.fonts.size.sm,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    color: theme.palette.darkBlue,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(0),
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  readMoreButton: {
    marginLeft: theme.spacing(3),
    fontSize: theme.typography.fonts.size.sm,
    fontWeight: 700,
    lineHeight: theme.typography.fonts.lineHeight.sm,
    color: theme.palette.mainBlue,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
  paragraph: {
    fontWeight: 700,
  },
}));

export default useCardStyles;
